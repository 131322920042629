<script>
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import {
  cattelService,
  posService,
  ioncService,
  birthService,
  visitService,
  dryService,
  milkrecordService,
  bodyService,
  motionService,
  illnesService,
} from "../../../helpers/cattel.service";
import datePicker from "@alireza-ab/vue-persian-datepicker";
import PersianDate from "@alireza-ab/persian-date";
import Swal from "sweetalert2";

/**
 * Form-element component
 */
export default {
  components: { datePicker, Layout },
  data() {
    return {
      title: "",
      cowId: "",
      CowType: [
        "شیری",
        "خشک",
        "تلیسه آبستن",
        "تلیسه غیرآبستن",
        "گوساله زیر یکسال",
      ],
      swalWithBootstrapButtons: Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      }),
      CowData: cattelService
        .getCow(this.$router.currentRoute.params.id)
        .then((data) => {
          if (data.message.length == 0) {
            this.$router.push({
              path: "/404",
              redirect: { name: "Error-404" },
            });
          } else {
            this.CowData = data.message;
            this.cowId = this.CowData.id;
            this.title = "دام شماره " + this.CowData.cow_num;
          }
        }),
      Positions: posService.getPositions().then((data) => {
        this.Positions = data.message;
      }),
      EditId: 0,
      submittedInoc: false,
      showmodalAddInoc: false,
      submittedBirth: false,
      showmodalAddBirth: false,
      submittedVisit: false,
      showmodalAddVisit: false,
      submittedDry: false,
      showmodalAddDry: false,
      submittedMilkrecord: false,
      showmodalAddMilkrecord: false,
      submittedBCS: false,
      showmodalAddBCS: false,
      submittedMotion: false,
      showmodalAddMotion: false,
      submittedIllnes: false,
      showmodalAddIllnes: false,
      VisitR: [
        { text: "نابارور", status: false },
        { text: "بارور", status: true },
        { text: "غیره", status: false },
      ],
      Inocs: ioncService
        .getInoc(this.$router.currentRoute.params.id)
        .then((data) => {
          if (data.message) {
            this.Inocs = data.message;
          } else {
            this.Inocs = [];
          }
        }),
      Births: birthService
        .getBirth(this.$router.currentRoute.params.id)
        .then((data) => {
          if (data.message) {
            this.Births = data.message;
          } else {
            this.Births = [];
          }
        }),
      Visits: visitService
        .getVisit(this.$router.currentRoute.params.id)
        .then((data) => {
          if (data.message) {
            this.Visits = data.message;
          } else {
            this.Visits = [];
          }
        }),
      Drys: dryService
        .getDry(this.$router.currentRoute.params.id)
        .then((data) => {
          if (data.message) {
            this.Drys = data.message;
          } else {
            this.Drys = [];
          }
        }),
      Milkrecords: milkrecordService
        .getMilkrecord(this.$router.currentRoute.params.id)
        .then((data) => {
          if (data.message) {
            this.Milkrecords = data.message;
          } else {
            this.Milkrecords = [];
          }
        }),
      BCSS: bodyService
        .getBodys(this.$router.currentRoute.params.id)
        .then((data) => {
          if (data.message) {
            this.BCSS = data.message;
          } else {
            this.BCSS = [];
          }
        }),
      Motions: motionService
        .getMotion(this.$router.currentRoute.params.id)
        .then((data) => {
          if (data.message) {
            this.Motions = data.message;
          } else {
            this.Motions = [];
          }
        }),
      Illness: illnesService
        .getIllnes(this.$router.currentRoute.params.id)
        .then((data) => {
          if (data.message) {
            this.Illness = data.message;
          } else {
            this.Illness = [];
          }
        }),
      NewInoc: {
        inoc_time: "",
        inoc_round: "",
        inoc_description: "",
      },
      NewBirth: {
        birth_birthtime: "",
        birth_description: "",
      },
      NewVisit: {
        visit_visittime: "",
        visit_visitresult: "",
        visit_visitreason: "",
        visit_description: "",
      },
      NewDry: {
        dry_drytime: "",
        dry_description: "",
      },
      NewMilkrecord: {
        milkrecord_recorddate: "",
        milkrecord_weight: "",
        milkrecord_description: "",
      },
      NewBCS: {
        bcs_scoretime: "",
        bcs_bcs: "",
        bcs_description: "",
      },
      NewMotion: {
        motion_scoretime: "",
        motion_movement_num: "",
        motion_description: "",
      },
      NewIllnes: {
        illnes_entrydate: "",
        illnes_reasonentry: "",
        illnes_departuredate: "",
        illnes_description: "",
      },
    };
  },
  validations: {
    CowData: {
      cow_num: { required },
      cow_type: { required },
      birthtime: { required },
      position: { required },
      registr_num: { required },
    },
    NewInoc: {
      inoc_time: { required },
      inoc_round: { required },
    },
    NewBirth: {
      birth_birthtime: { required },
    },
    NewVisit: {
      visit_visittime: { required },
      visit_visitresult: { required },
      visit_visitreason: { required },
    },
    NewDry: {
      dry_drytime: { required },
    },
    NewMilkrecord: {
      milkrecord_recorddate: { required },
      milkrecord_weight: { required },
    },
    NewBCS: {
      bcs_scoretime: { required },
      bcs_bcs: { required },
    },
    NewMotion: {
      motion_scoretime: { required },
      motion_movement_num: { required },
    },
    NewIllnes: {
      illnes_entrydate: { required },
      illnes_reasonentry: { required },
      illnes_departuredate: { required },
    },
  },
  methods: {
    CheckAccessOnce(capp) {
      return this.$CheckAccessOnce(capp);
    },
    EditSave() {
      this.$v.$touch();
      if (this.$v.CowData.$invalid) {
        return;
      } else {
        this.swalWithBootstrapButtons
          .fire({
            title: "آیا مطمئن هستید ؟",
            text: "از تغییرات روی دام مطمئن هستید ؟",
            icon: "warning",
            confirmButtonText: "تایید",
            cancelButtonText: "لغو",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {
              const Cownum = this.CowData.cow_num;
              const CowType = this.CowData.cow_type;
              const Birthtime = this.CowData.birthtime;
              const Position = this.CowData.position;
              const RegistrNum = this.CowData.registr_num;
              const Description = this.CowData.description;
              cattelService
                .editCow(
                  this.cowId,
                  Cownum,
                  Birthtime,
                  CowType,
                  Position,
                  RegistrNum,
                  Description
                )
                .then((data) => {
                  this.swalWithBootstrapButtons.fire({
                    text: data.message,
                    icon: data.err,
                    confirmButtonText: "تایید",
                  });
                });
            } else {
              this.swalWithBootstrapButtons.fire({
                title: "لغو شد",
                text: "تنظیمات شما بر روی کاربر اعمال نشد",
                icon: "error",
                confirmButtonText: "تایید",
              });
            }
          });
      }
    },
    Delete(id, index, type) {
      this.swalWithBootstrapButtons
        .fire({
          title: "آیا مطمئن هستید ؟",
          text: "شما دیگر نمی‌توانید این را برگردانید",
          icon: "warning",
          confirmButtonText: "حذف",
          cancelButtonText: "لغو",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            switch (type) {
              case "inoc": {
                ioncService.deleteInoc(id).then((data) => {
                  this.Inocs.splice(index, 1);
                  this.ShowSW(data);
                });
                break;
              }
              case "birth": {
                birthService
                  .deleteBirth(id, this.$router.currentRoute.params.id)
                  .then((data) => {
                    this.Births.splice(index, 1);
                    this.ShowSW(data);
                  });
                break;
              }
              case "visit": {
                visitService.deleteVisit(id).then((data) => {
                  this.Visits.splice(index, 1);
                  this.ShowSW(data);
                });
                break;
              }
              case "dry": {
                dryService.deleteDry(id).then((data) => {
                  this.Drys.splice(index, 1);
                  this.ShowSW(data);
                });
                break;
              }
              case "milkrecord": {
                milkrecordService.deleteMilkrecord(id).then((data) => {
                  this.Milkrecords.splice(index, 1);
                  this.ShowSW(data);
                });
                break;
              }
              case "bcs": {
                bodyService.deleteBodys(id).then((data) => {
                  this.BCSS.splice(index, 1);
                  this.ShowSW(data);
                });
                break;
              }
              case "motion": {
                motionService.deleteMotion(id).then((data) => {
                  this.Motions.splice(index, 1);
                  this.ShowSW(data);
                });
                break;
              }
              case "illnes": {
                illnesService.deleteIllnes(id).then((data) => {
                  this.Illness.splice(index, 1);
                  this.ShowSW(data);
                });
                break;
              }
            }
          }
        });
    },
    Submit(type) {
      this.$v.$touch();
      switch (type) {
        case "inoc": {
          this.submittedInoc = true;
          if (this.$v.NewInoc.$invalid) {
            return;
          } else {
            let Sendddata = {
              inoculate_time: this.NewInoc.inoc_time,
              inoculate_round: this.NewInoc.inoc_round,
              description: this.NewInoc.inoc_description,
            };
            ioncService.editInoc(this.EditId, Sendddata).then((data) => {
              this.ShowSW(data);
              this.showmodalAddInoc = false;
              this.submittedInoc = false;
            });
          }
          break;
        }
        case "birth": {
          this.submittedBirth = true;
          if (this.$v.NewBirth.$invalid) {
            return;
          } else {
            let Sendddata = {
              birthtime: this.NewBirth.birth_birthtime,
              description: this.NewBirth.birth_description,
            };
            birthService.editBirth(this.EditId, Sendddata).then((data) => {
              this.ShowSW(data);
              this.showmodalAddBirth = false;
              this.submittedBirth = false;
            });
          }
          break;
        }
        case "visit": {
          this.submittedVisit = true;
          if (this.$v.NewVisit.$invalid) {
            return;
          } else {
            let Sendddata = {
              visittime: this.NewVisit.visit_visittime,
              visitresult: this.NewVisit.visit_visitresult,
              visitreason: this.NewVisit.visit_visitreason,
              description: this.NewVisit.visit_description,
            };
            visitService.editVisit(this.EditId, Sendddata).then((data) => {
              this.ShowSW(data);
              this.showmodalAddVisit = false;
              this.submittedVisit = false;
            });
          }
          break;
        }
        case "dry": {
          this.submittedDry = true;
          if (this.$v.NewDry.$invalid) {
            return;
          } else {
            let Sendddata = {
              drytime: this.NewDry.dry_drytime,
              description: this.NewDry.dry_description,
            };
            dryService.editDry(this.EditId, Sendddata).then((data) => {
              this.ShowSW(data);
              this.showmodalAddDry = false;
              this.submittedDry = false;
            });
          }
          break;
        }
        case "milkrecord": {
          this.submittedMilkrecord = true;
          if (this.$v.NewMilkrecord.$invalid) {
            return;
          } else {
            let Sendddata = {
              recorddate: this.NewMilkrecord.milkrecord_recorddate,
              weight: this.NewMilkrecord.milkrecord_weight,
              description: this.NewMilkrecord.milkrecord_description,
            };
            milkrecordService
              .editMilkrecord(this.EditId, Sendddata)
              .then((data) => {
                this.ShowSW(data);
                this.showmodalAddMilkrecord = false;
                this.submittedMilkrecord = false;
              });
          }
          break;
        }
        case "bcs": {
          this.submittedBCS = true;
          if (this.$v.NewBCS.$invalid) {
            return;
          } else {
            let Sendddata = {
              scoretime: this.NewBCS.bcs_scoretime,
              bcs: this.NewBCS.bcs_bcs,
              description: this.NewBCS.bcs_description,
            };
            bodyService.editBodys(this.EditId, Sendddata).then((data) => {
              this.ShowSW(data);
              this.showmodalAddBCS = false;
              this.submittedBCS = false;
            });
          }
          break;
        }
        case "motion": {
          this.submittedMotion = true;
          if (this.$v.NewMotion.$invalid) {
            return;
          } else {
            let Sendddata = {
              scoretime: this.NewMotion.motion_scoretime,
              movement_num: this.NewMotion.motion_movement_num,
              description: this.NewMotion.motion_description,
            };
            motionService.editMotion(this.EditId, Sendddata).then((data) => {
              this.ShowSW(data);
              this.showmodalAddMotion = false;
              this.submittedMotion = false;
            });
          }
          break;
        }
        case "illnes": {
          this.submittedIllnes = true;
          if (this.$v.NewIllnes.$invalid) {
            return;
          } else {
            let Sendddata = {
              entrydate: this.NewIllnes.illnes_entrydate,
              reasonentry: this.NewIllnes.illnes_reasonentry,
              departuredate: this.NewIllnes.illnes_departuredate,
              description: this.NewIllnes.illnes_description,
            };
            illnesService.editIllnes(this.EditId, Sendddata).then((data) => {
              this.ShowSW(data);
              this.showmodalAddIllnes = false;
              this.submittedIllnes = false;
            });
          }
          break;
        }
      }
    },
    Edit(editId, type) {
      this.EditId = editId;
      switch (type) {
        case "inoc": {
          this.showmodalAddInoc = true;
          this.Inocs.filter((data) => {
            if (data.id == editId) {
              let fromJalali = new PersianDate(data.inoculate_time, "jalali");
              this.NewInoc.inoc_time = fromJalali.calendar("g").toString();
              this.NewInoc.inoc_births_num = data.births_num;
              this.NewInoc.inoc_round = data.inoculate_round;
              this.NewInoc.inoc_description = data.description;
            }
          });
          break;
        }
        case "birth": {
          this.showmodalAddBirth = true;
          this.Births.filter((data) => {
            if (data.id == editId) {
              let fromJalali = new PersianDate(data.birthtime, "jalali");
              this.NewBirth.birth_birthtime = fromJalali
                .calendar("g")
                .toString();
              this.NewBirth.birth_breastfeeding = data.breastfeeding;
              this.NewBirth.birth_description = data.description;
            }
          });
          break;
        }
        case "visit": {
          this.showmodalAddVisit = true;
          this.Visits.filter((data) => {
            if (data.id == editId) {
              let fromJalali = new PersianDate(data.visittime, "jalali");
              this.NewVisit.visit_visittime = fromJalali
                .calendar("g")
                .toString();
              this.NewVisit.visit_visitresult = data.visitresult;
              this.NewVisit.visit_visitreason = data.visitreason;
              this.NewVisit.visit_births_num = data.births_num;
              this.NewVisit.visit_description = data.description;
            }
          });
          break;
        }
        case "dry": {
          this.showmodalAddDry = true;
          this.Drys.filter((data) => {
            if (data.id == editId) {
              let fromJalali = new PersianDate(data.drytime, "jalali");
              this.NewDry.dry_drytime = fromJalali.calendar("g").toString();
              this.NewDry.dry_births_num = data.births_num;
              this.NewDry.dry_description = data.description;
            }
          });
          break;
        }
        case "milkrecord": {
          this.showmodalAddMilkrecord = true;
          this.Milkrecords.filter((data) => {
            if (data.id == editId) {
              let fromJalali = new PersianDate(data.recorddate, "jalali");
              this.NewMilkrecord.milkrecord_recorddate = fromJalali
                .calendar("g")
                .toString();
              this.NewMilkrecord.milkrecord_weight = data.weight;
              this.NewMilkrecord.milkrecord_births_num = data.births_num;
              this.NewMilkrecord.milkrecord_description = data.description;
            }
          });
          break;
        }
        case "bcs": {
          this.showmodalAddBCS = true;
          this.BCSS.filter((data) => {
            if (data.id == editId) {
              let fromJalali = new PersianDate(data.scoretime, "jalali");
              this.NewBCS.bcs_scoretime = fromJalali.calendar("g").toString();
              this.NewBCS.bcs_bcs = data.bcs;
              this.NewBCS.bcs_description = data.description;
            }
          });
          break;
        }
        case "motion": {
          this.showmodalAddMotion = true;
          this.Motions.filter((data) => {
            if (data.id == editId) {
              let fromJalali = new PersianDate(data.scoretime, "jalali");
              this.NewMotion.motion_scoretime = fromJalali
                .calendar("g")
                .toString();
              this.NewMotion.motion_movement_num = data.movement_num;
              this.NewMotion.motion_description = data.description;
            }
          });
          break;
        }
        case "illnes": {
          this.showmodalAddIllnes = true;
          this.Illness.filter((data) => {
            if (data.id == editId) {
              let entrydatej = new PersianDate(data.entrydate, "jalali");
              let departuredatej = new PersianDate(
                data.departuredate,
                "jalali"
              );
              this.NewIllnes.illnes_entrydate = entrydatej
                .calendar("g")
                .toString();
              this.NewIllnes.illnes_departuredate = departuredatej
                .calendar("g")
                .toString();
              this.NewIllnes.illnes_reasonentry = data.reasonentry;
              this.NewIllnes.illnes_description = data.description;
            }
          });
          break;
        }
      }
    },
    ShowSW(data) {
      this.swalWithBootstrapButtons.fire({
        text: data.message,
        icon: data.err,
        confirmButtonText: "تایید",
      });
    },
    hideModal() {
      (this.EditId = 0),
        (this.submittedInoc = false),
        (this.showmodalAddInoc = false),
        (this.submittedBirth = false),
        (this.showmodalAddBirth = false),
        (this.submittedVisit = false),
        (this.showmodalAddVisit = false),
        (this.submittedDry = false),
        (this.showmodalAddDry = false),
        (this.submittedMilkrecord = false),
        (this.showmodalAddMilkrecord = false),
        (this.submittedBCS = false),
        (this.showmodalAddBCS = false),
        (this.submittedMotion = false),
        (this.showmodalAddMotion = false),
        (this.submittedIllnes = false),
        (this.showmodalAddIllnes = false),
        (this.NewInoc = {});
      this.NewBirth = {};
      this.NewVisit = {};
      this.NewDry = {};
      this.NewMilkrecord = {};
      this.NewBCS = {};
      this.NewMotion = {};
      this.NewIllnes = {};
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3 class="title">{{ title }}</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <b-row>
                    <b-col md="4">
                      <b-form-group label="شماره دام" label-for="cow_num">
                        <b-form-input
                          type="number"
                          for="cow_num"
                          id="cow_num"
                          :value="CowData.cow_num"
                          v-model="CowData.cow_num"
                          :class="{
                            'is-invalid': $v.CowData.cow_num.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="!$v.CowData.cow_num.required"
                          class="invalid-feedback"
                        >
                          شماره دام الزامی است
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="4"
                      ><b-form-group label="شماره ثبت" label-for="registr_num">
                        <b-form-input
                          id="registr_num"
                          :value="CowData.registr_num"
                          type="number"
                          name="registr_num"
                          v-model="CowData.registr_num"
                          :class="{
                            'is-invalid': $v.CowData.registr_num.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="!$v.CowData.registr_num.required"
                          class="invalid-feedback"
                        >
                          شماره همراه / نام کاربری الزامی است
                        </div>
                      </b-form-group></b-col
                    >
                    <b-col md="4">
                      <div class="form-group">
                        <date-picker
                          required
                          label="تاریخ تولد"
                          :column="1"
                          readonly
                          format="jYYYY/jMM/jDD"
                          mode="single"
                          type="date"
                          click-on="all"
                          locale="fa"
                          v-model="CowData.birthtime"
                        >
                          <template #after>
                            <small
                              v-if="$v.CowData.birthtime.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.CowData.birthtime.required"
                                >تاریخ تولد الزامی است</span
                              >
                            </small>
                          </template>
                        </date-picker>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <div class="form-group row">
                        <label class="col-md-2 col-form-label"> نوع </label>
                        <div class="col-md-12">
                          <select
                            id="cow_type"
                            v-model="CowData.cow_type"
                            type="cow_type"
                            name="cow_type"
                            class="form-control custom-select"
                            :class="{
                              'is-invalid': $v.CowData.cow_type.$error,
                            }"
                          >
                            <option
                              v-for="(item, index) in CowType"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <div class="form-group row">
                        <label class="col-md-2 col-form-label">جایگاه</label>
                        <div class="col-md-12">
                          <select
                            id="position"
                            v-model="CowData.position"
                            type="position"
                            name="position"
                            class="form-control custom-select"
                            :class="{
                              'is-invalid': $v.CowData.position.$error,
                            }"
                          >
                            <option
                              v-for="(item, index) in Positions"
                              :key="index"
                              :value="item.id"
                            >
                              {{ item.position }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="form-group">
                        <label>توضیحات</label>
                        <div>
                          <textarea
                            v-model="CowData.description"
                            class="form-control"
                            name="textarea"
                            rows="5"
                          ></textarea>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12" class="row justify-content-end">
                      <a
                        href="javascript:void(0);"
                        class="btn btn-info col-md-1"
                        v-if="CheckAccessOnce('EditCow')"
                        @click="EditSave"
                        >ذخیره</a
                      >
                    </b-col>
                  </b-row>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-md">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">ویرایش ها دیگر</h4>
                    <b-tabs
                      pills
                      justified
                      nav-class="p-0"
                      content-class="pt-0 px-2 text-muted"
                    >
                      <b-tab title="تلقیح" active title-item-class="mb-2">
                        <b-card-text>
                          <div class="table-responsive mt-3">
                            <table
                              class="table table-centered datatable dt-responsive nowrap"
                            >
                              <thead class="thead-light">
                                <tr>
                                  <th class="text-center">شناسه</th>
                                  <th class="text-center">زمان تلقیح</th>
                                  <th class="text-center">دوره تلقیح</th>
                                  <th class="text-center">شیراوری</th>
                                  <th class="text-center">زمان ثبت</th>
                                  <th class="text-center">توضیحات</th>
                                  <th class="text-center">عملیات</th>
                                </tr>
                              </thead>
                              <tbody v-if="Inocs">
                                <tr v-for="(item, index) in Inocs" :key="index">
                                  <td class="text-center">{{ item.id }}</td>
                                  <td class="text-center">
                                    {{ item.inoculate_time }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.inoculate_round }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.births_num }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.set_time }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.description }}
                                  </td>
                                  <td class="text-center">
                                    <a
                                      href="javascript:void(0);"
                                      class="text-primary"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('EditInoc')"
                                      @click="Edit(item.id, 'inoc')"
                                      title="ویرایش"
                                    >
                                      <i
                                        class="mdi mdi-pencil font-size-18"
                                      ></i>
                                    </a>

                                    <a
                                      href="javascript:void(0);"
                                      class="text-danger"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('DeleteInoc')"
                                      @click="Delete(item.id, index, 'inoc')"
                                      title="حذف"
                                    >
                                      <i
                                        class="mdi mdi-trash-can font-size-18"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="زایش" title-item-class="mb-2">
                        <b-card-text>
                          <div class="table-responsive mt-3">
                            <table
                              class="table table-centered datatable dt-responsive nowrap"
                            >
                              <thead class="thead-light">
                                <tr>
                                  <th class="text-center">شناسه</th>
                                  <th class="text-center">زمان زایش</th>
                                  <th class="text-center">شیراوری</th>
                                  <th class="text-center">زمان ثبت</th>
                                  <th class="text-center">توضیحات</th>
                                  <th class="text-center">عملیات</th>
                                </tr>
                              </thead>
                              <tbody v-if="Births">
                                <tr
                                  v-for="(item, index) in Births"
                                  :key="index"
                                >
                                  <td class="text-center">{{ item.id }}</td>
                                  <td class="text-center">
                                    {{ item.birthtime }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.breastfeeding }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.set_time }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.description }}
                                  </td>
                                  <td class="text-center">
                                    <a
                                      href="javascript:void(0);"
                                      class="text-primary"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('EditBirth')"
                                      @click="Edit(item.id, 'birth')"
                                      title="ویرایش"
                                    >
                                      <i
                                        class="mdi mdi-pencil font-size-18"
                                      ></i>
                                    </a>

                                    <a
                                      href="javascript:void(0);"
                                      class="text-danger"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('DeleteBirth')"
                                      @click="Delete(item.id, index, 'birth')"
                                      title="حذف"
                                    >
                                      <i
                                        class="mdi mdi-trash-can font-size-18"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="ویزیت" title-item-class="mb-2">
                        <b-card-text>
                          <div class="table-responsive mt-3">
                            <table
                              class="table table-centered datatable dt-responsive nowrap"
                            >
                              <thead class="thead-light">
                                <tr>
                                  <th class="text-center">شناسه</th>
                                  <th class="text-center">زمان ویزیت</th>
                                  <th class="text-center">نتیجه ویزیت</th>
                                  <th class="text-center">علت ویزیت</th>
                                  <th class="text-center">شیراوری</th>
                                  <th class="text-center">زمان ثبت</th>
                                  <th class="text-center">توضیحات</th>
                                  <th class="text-center">عملیات</th>
                                </tr>
                              </thead>
                              <tbody v-if="Visits">
                                <tr
                                  v-for="(item, index) in Visits"
                                  :key="index"
                                >
                                  <td class="text-center">{{ item.id }}</td>
                                  <td class="text-center">
                                    {{ item.visittime }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.visitresult }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.visitreason }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.births_num }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.set_time }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.description }}
                                  </td>
                                  <td class="text-center">
                                    <a
                                      href="javascript:void(0);"
                                      class="text-primary"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('EditVisit')"
                                      @click="Edit(item.id, 'visit')"
                                      title="ویرایش"
                                    >
                                      <i
                                        class="mdi mdi-pencil font-size-18"
                                      ></i>
                                    </a>

                                    <a
                                      href="javascript:void(0);"
                                      class="text-danger"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('DeleteVisit')"
                                      @click="Delete(item.id, index, 'visit')"
                                      title="حذف"
                                    >
                                      <i
                                        class="mdi mdi-trash-can font-size-18"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="خشکی">
                        <b-card-text>
                          <div class="table-responsive mt-3">
                            <table
                              class="table table-centered datatable dt-responsive nowrap"
                            >
                              <thead class="thead-light">
                                <tr>
                                  <th class="text-center">شناسه</th>
                                  <th class="text-center">زمان خشکی</th>
                                  <th class="text-center">شیراوری</th>
                                  <th class="text-center">زمان ثبت</th>
                                  <th class="text-center">توضیحات</th>
                                  <th class="text-center">عملیات</th>
                                </tr>
                              </thead>
                              <tbody v-if="Drys">
                                <tr v-for="(item, index) in Drys" :key="index">
                                  <td class="text-center">{{ item.id }}</td>
                                  <td class="text-center">
                                    {{ item.drytime }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.births_num }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.set_time }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.description }}
                                  </td>
                                  <td class="text-center">
                                    <a
                                      href="javascript:void(0);"
                                      class="text-primary"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('EditDry')"
                                      @click="Edit(item.id, 'dry')"
                                      title="ویرایش"
                                    >
                                      <i
                                        class="mdi mdi-pencil font-size-18"
                                      ></i>
                                    </a>

                                    <a
                                      href="javascript:void(0);"
                                      class="text-danger"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('DeleteDry')"
                                      @click="Delete(item.id, index, 'dry')"
                                      title="حذف"
                                    >
                                      <i
                                        class="mdi mdi-trash-can font-size-18"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="ثبت شیر" title-item-class="mb-2">
                        <b-card-text>
                          <div class="table-responsive mt-3">
                            <table
                              class="table table-centered datatable dt-responsive nowrap"
                            >
                              <thead class="thead-light">
                                <tr>
                                  <th class="text-center">شناسه</th>
                                  <th class="text-center">زمان ثبت شیر</th>
                                  <th class="text-center">وزن شیر</th>
                                  <th class="text-center">شیراوری</th>
                                  <th class="text-center">زمان ثبت</th>
                                  <th class="text-center">توضیحات</th>
                                  <th class="text-center">عملیات</th>
                                </tr>
                              </thead>
                              <tbody v-if="Milkrecords">
                                <tr
                                  v-for="(item, index) in Milkrecords"
                                  :key="index"
                                >
                                  <td class="text-center">{{ item.id }}</td>
                                  <td class="text-center">
                                    {{ item.recorddate }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.weight }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.births_num }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.set_time }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.description }}
                                  </td>
                                  <td class="text-center">
                                    <a
                                      href="javascript:void(0);"
                                      class="text-primary"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('EditMilkrecord')"
                                      @click="Edit(item.id, 'milkrecord')"
                                      title="ویرایش"
                                    >
                                      <i
                                        class="mdi mdi-pencil font-size-18"
                                      ></i>
                                    </a>

                                    <a
                                      href="javascript:void(0);"
                                      class="text-danger"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('DeleteMilkrecord')"
                                      @click="
                                        Delete(item.id, index, 'milkrecord')
                                      "
                                      title="حذف"
                                    >
                                      <i
                                        class="mdi mdi-trash-can font-size-18"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="نمره bcs" title-item-class="mb-2">
                        <b-card-text>
                          <div class="table-responsive mt-3">
                            <table
                              class="table table-centered datatable dt-responsive nowrap"
                            >
                              <thead class="thead-light">
                                <tr>
                                  <th class="text-center">شناسه</th>
                                  <th class="text-center">زمان ثبت نمره</th>
                                  <th class="text-center">نمره bcs</th>
                                  <th class="text-center">زمان ثبت</th>
                                  <th class="text-center">توضیحات</th>
                                  <th class="text-center">عملیات</th>
                                </tr>
                              </thead>
                              <tbody v-if="BCSS">
                                <tr v-for="(item, index) in BCSS" :key="index">
                                  <td class="text-center">{{ item.id }}</td>
                                  <td class="text-center">
                                    {{ item.scoretime }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.bcs }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.set_time }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.description }}
                                  </td>
                                  <td class="text-center">
                                    <a
                                      href="javascript:void(0);"
                                      class="text-primary"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('EditBodys')"
                                      @click="Edit(item.id, 'bcs')"
                                      title="ویرایش"
                                    >
                                      <i
                                        class="mdi mdi-pencil font-size-18"
                                      ></i>
                                    </a>

                                    <a
                                      href="javascript:void(0);"
                                      class="text-danger"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('DeleteBodys')"
                                      @click="Delete(item.id, index, 'bcs')"
                                      title="حذف"
                                    >
                                      <i
                                        class="mdi mdi-trash-can font-size-18"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="نمره حرکتی" title-item-class="mb-2">
                        <b-card-text>
                          <div class="table-responsive mt-3">
                            <table
                              class="table table-centered datatable dt-responsive nowrap"
                            >
                              <thead class="thead-light">
                                <tr>
                                  <th class="text-center">شناسه</th>
                                  <th class="text-center">زمان ثبت نمره</th>
                                  <th class="text-center">نمره حرکتی</th>
                                  <th class="text-center">زمان ثبت</th>
                                  <th class="text-center">توضیحات</th>
                                  <th class="text-center">عملیات</th>
                                </tr>
                              </thead>
                              <tbody v-if="Motions">
                                <tr
                                  v-for="(item, index) in Motions"
                                  :key="index"
                                >
                                  <td class="text-center">{{ item.id }}</td>
                                  <td class="text-center">
                                    {{ item.scoretime }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.movement_num }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.set_time }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.description }}
                                  </td>
                                  <td class="text-center">
                                    <a
                                      href="javascript:void(0);"
                                      class="text-primary"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('EditMotion')"
                                      @click="Edit(item.id, 'motion')"
                                      title="ویرایش"
                                    >
                                      <i
                                        class="mdi mdi-pencil font-size-18"
                                      ></i>
                                    </a>

                                    <a
                                      href="javascript:void(0);"
                                      class="text-danger"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('DeleteMotion')"
                                      @click="Delete(item.id, index, 'motion')"
                                      title="حذف"
                                    >
                                      <i
                                        class="mdi mdi-trash-can font-size-18"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="بیمارستان">
                        <b-card-text>
                          <div class="table-responsive mt-3">
                            <table
                              class="table table-centered datatable dt-responsive nowrap"
                            >
                              <thead class="thead-light">
                                <tr>
                                  <th class="text-center">شناسه</th>
                                  <th class="text-center">علت ورود</th>
                                  <th class="text-center">تاریخ ورود</th>
                                  <th class="text-center">تاریخ خروج</th>
                                  <th class="text-center">تاریخ ثبت</th>
                                  <th class="text-center">توضیحات</th>
                                  <th class="text-center">عملیات</th>
                                </tr>
                              </thead>
                              <tbody v-if="Illness">
                                <tr
                                  v-for="(item, index) in Illness"
                                  :key="index"
                                >
                                  <td class="text-center">{{ item.id }}</td>
                                  <td class="text-center">
                                    {{ item.reasonentry }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.entrydate }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.departuredate }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.set_time }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.description }}
                                  </td>
                                  <td class="text-center">
                                    <a
                                      href="javascript:void(0);"
                                      class="text-primary"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('EditIllnes')"
                                      @click="Edit(item.id, 'illnes')"
                                      title="ویرایش"
                                    >
                                      <i
                                        class="mdi mdi-pencil font-size-18"
                                      ></i>
                                    </a>

                                    <a
                                      href="javascript:void(0);"
                                      class="text-danger"
                                      v-b-tooltip.hover
                                      v-if="CheckAccessOnce('DeleteIllnes')"
                                      @click="Delete(item.id, index, 'illnes')"
                                      title="حذف"
                                    >
                                      <i
                                        class="mdi mdi-trash-can font-size-18"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-card-text>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
          <router-link
            :to="'/cattel/show/' + $router.currentRoute.params.id"
            class="side-nav-link-ref"
          >
            <button class="btn btn-success col-12">نمایش این دام</button>
          </router-link>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <b-modal
      id="modalInoc"
      v-if="CheckAccessOnce('EditInoc')"
      v-model="showmodalAddInoc"
      title="ویرایش تلقیح"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="Submit('inoc')">
        <div class="form-group" id="inoculate_time">
          <date-picker
            required
            label="تاریخ تلقیح"
            :column="1"
            readonly
            mode="single"
            type="date"
            format="jYYYY/jMM/jDD"
            click-on="all"
            locale="fa"
            v-model="NewInoc.inoc_time"
          >
            <template #after>
              <small
                v-if="submittedInoc && $v.NewInoc.inoc_time.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewInoc.inoc_time.required"
                  >تاریخ تلقیح الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label for="inoculate_round">نوبت تلقیح</label>
          <input
            id="inoculate_round"
            v-model="NewInoc.inoc_round"
            type="number"
            class="form-control"
            placeholder="نوبت تلقیح"
            :class="{
              'is-invalid': submittedInoc && $v.NewInoc.inoc_round.$error,
            }"
          />
          <div
            v-if="submittedInoc && !$v.NewInoc.inoc_round.required"
            class="invalid-feedback"
          >
            نوبت تلقیح الزامی است
          </div>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewInoc.inoc_description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalBirth"
      v-if="CheckAccessOnce('EditBirth')"
      v-model="showmodalAddBirth"
      title="ویرایش زایش"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="Submit('birth')">
        <div class="form-group">
          <date-picker
            required
            label="تاریخ زایش"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewBirth.birth_birthtime"
          >
            <template #after>
              <small
                v-if="submittedBirth && $v.NewBirth.birth_birthtime.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewBirth.birth_birthtime.required"
                  >تاریخ زایش الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewBirth.birth_description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalVisit"
      v-if="CheckAccessOnce('EditVisit')"
      v-model="showmodalAddVisit"
      title="ویرایش ویزیت"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="Submit('visit')">
        <div class="form-group">
          <date-picker
            required
            label="تاریخ ویزیت"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewVisit.visit_visittime"
          >
            <template #after>
              <small
                v-if="submittedVisit && $v.NewVisit.visit_visittime.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewVisit.visit_visittime.required"
                  >تاریخ ویزیت الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label for="visit-visitreason">علت ویزیت</label>
          <input
            id="visit-visitreason"
            v-model="NewVisit.visit_visitreason"
            type="text"
            class="form-control"
            placeholder="علت ویزیت"
            :class="{
              'is-invalid':
                submittedVisit && $v.NewVisit.visit_visitreason.$error,
            }"
          />
          <div
            v-if="submittedVisit && !$v.NewVisit.visit_visitreason.required"
            class="invalid-feedback"
          >
            علت ویزیت الزامی است
          </div>
        </div>
        <div class="form-group">
          <label for="visit-visitresult">نتیجه ویزیت</label>
          <select
            id="visit-visitresult"
            v-model="NewVisit.visitresult"
            type="visit-visitresult"
            name="visit-visitresult"
            class="form-control custom-select"
            :class="{
              'is-invalid':
                submittedVisit && $v.NewVisit.visit_visitresult.$error,
            }"
          >
            <option
              v-for="(item, index) in VisitR"
              :key="index"
              :value="item.status"
            >
              {{ item.text }}
            </option>
          </select>
          <div
            v-if="submittedVisit && !$v.NewVisit.visit_visitresult.required"
            class="invalid-feedback"
          >
            نتیجه ویزیت الزامی است
          </div>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewVisit.visit_description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalDry"
      v-if="CheckAccessOnce('EditDry')"
      v-model="showmodalAddDry"
      title="ویرایش خشکی"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="Submit('dry')">
        <div class="form-group">
          <date-picker
            required
            label="تاریخ خشکی"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewDry.dry_drytime"
          >
            <template #after>
              <small
                v-if="submittedDry && $v.NewDry.dry_drytime.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewDry.dry_drytime.required"
                  >تاریخ خشکی الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewDry.dry_description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalMilkrecord"
      v-if="CheckAccessOnce('EditMilkrecord')"
      v-model="showmodalAddMilkrecord"
      title="ویرایش رکورد شیر"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="Submit('milkrecord')">
        <div class="form-group">
          <label for="milk-weight">وزن شیر</label>
          <input
            id="milk-weight"
            v-model="NewMilkrecord.milkrecord_weight"
            type="number"
            step="0.5"
            class="form-control"
            placeholder="وزن شیر"
            :class="{
              'is-invalid':
                submittedMilkrecord &&
                $v.NewMilkrecord.milkrecord_weight.$error,
            }"
          />
          <div
            v-if="
              submittedMilkrecord &&
              !$v.NewMilkrecord.milkrecord_weight.required
            "
            class="invalid-feedback"
          >
            وزن شیر الزامی است
          </div>
        </div>
        <div class="form-group" id="recorddate">
          <date-picker
            required
            label="تاریخ رکورد شیر"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewMilkrecord.milkrecord_recorddate"
          >
            <template #after>
              <small
                v-if="
                  submittedMilkrecord &&
                  $v.NewMilkrecord.milkrecord_recorddate.$error
                "
                class="invalid-feedback"
              >
                <span v-if="!$v.NewMilkrecord.milkrecord_recorddate.required"
                  >تاریخ رکورد شیر الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewMilkrecord.milkrecord_description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalBCS"
      v-if="CheckAccessOnce('EditBodys')"
      v-model="showmodalAddBCS"
      title="ویرایش نمره بدن"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="Submit('bcs')">
        <div class="form-group">
          <label for="bcs-bcs">نمره bcs</label>
          <input
            id="bcs-bcs"
            v-model="NewBCS.bcs_bcs"
            type="number"
            step="0.5"
            min="1"
            max="5"
            class="form-control"
            placeholder="نمره bcs"
            :class="{
              'is-invalid': submittedBCS && $v.NewBCS.bcs_bcs.$error,
            }"
          />
          <div
            v-if="submittedBCS && !$v.NewBCS.bcs_bcs.required"
            class="invalid-feedback"
          >
            نمره bcs الزامی است
          </div>
        </div>
        <div class="form-group" id="scoretime">
          <date-picker
            required
            label="تاریخ ثبت bcs"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewBCS.bcs_scoretime"
          >
            <template #after>
              <small
                v-if="submittedBCS && $v.NewBCS.bcs_scoretime.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewBCS.bcs_scoretime.required"
                  >تاریخ ثبت bcs الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewBCS.bcs_description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalMotion"
      v-if="CheckAccessOnce('EditMotion')"
      v-model="showmodalAddMotion"
      title="ویرایش نمره حرکتی"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="Submit('motion')">
        <div class="form-group">
          <label for="motion-movement_num">نمره حرکتی</label>
          <input
            id="motion-movement_num"
            v-model="NewMotion.motion_movement_num"
            type="number"
            step="0.5"
            min="1"
            max="5"
            class="form-control"
            placeholder="نمره حرکتی"
            :class="{
              'is-invalid':
                submittedMotion && $v.NewMotion.motion_movement_num.$error,
            }"
          />
          <div
            v-if="submittedMotion && !$v.NewMotion.motion_movement_num.required"
            class="invalid-feedback"
          >
            نمره حرکتی الزامی است
          </div>
        </div>
        <div class="form-group">
          <date-picker
            required
            label="تاریخ ثبت نمره"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewMotion.motion_scoretime"
          >
            <template #after>
              <small
                v-if="submittedMotion && $v.NewMotion.motion_scoretime.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewMotion.motion_scoretime.required"
                  >تاریخ ثبت نمره الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewMotion.motion_description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalIllnes"
      v-if="CheckAccessOnce('EditIllnes')"
      v-model="showmodalAddIllnes"
      title="ویرایش بیمارستان"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="Submit('illnes')">
        <div class="form-group">
          <label for="illnes-reasonentry">علت بیماری</label>
          <input
            id="illnes-reasonentry"
            v-model="NewIllnes.illnes_reasonentry"
            type="text"
            class="form-control"
            placeholder="علت بیماری"
            :class="{
              'is-invalid':
                submittedIllnes && $v.NewIllnes.illnes_reasonentry.$error,
            }"
          />
          <div
            v-if="submittedIllnes && !$v.NewIllnes.illnes_reasonentry.required"
            class="invalid-feedback"
          >
            علت بیماری الزامی است
          </div>
        </div>
        <div class="form-group" id="entrydate">
          <date-picker
            required
            label="تاریخ ورود"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewIllnes.illnes_entrydate"
          >
            <template #after>
              <small
                v-if="submittedIllnes && $v.NewIllnes.illnes_entrydate.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewIllnes.illnes_entrydate.required"
                  >تاریخ ورود الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group" id="departuredate">
          <date-picker
            required
            label="تاریخ خروج"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewIllnes.illnes_departuredate"
          >
            <template #after>
              <small
                v-if="
                  submittedIllnes && $v.NewIllnes.illnes_departuredate.$error
                "
                class="invalid-feedback"
              >
                <span v-if="!$v.NewIllnes.departuredate.required"
                  >تاریخ خروج الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewIllnes.illnes_description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <!-- end row -->
  </Layout>
</template>